<template>
    <div class="article-box" v-loading="loading">
        <!-- 导航 -->
        <div class="page-nav clearfix">
            <ul>
                <li class = "nav-item">
                    会员管理&nbsp;&nbsp;/
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;等级变更申请
                </li>
            </ul>
        </div>
        <!-- 标题 -->
        <div class="pag-title clearfix">
            <div class="page-title-name pull-left">等级变更申请</div>
        </div>
        <!-- 搜索栏 -->
        <div class="search-box clearfix">
            <div class="search-item pull-left">
                <el-input v-model="searchName" clearable size="small" style="width: 200px;height: 32px" placeholder="请输入单位全称或账号">
                </el-input>
            </div>
            <div class="search-label pull-left">本机构等级:</div>
            <div class="search-item pull-left">
                <el-select v-model="searchOrgLevel" clearable  size="small" style="width: 150px;height: 32px">
                    <el-option
                    v-for="item in orgLevel"
                    :key="'orgLevel' + item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search-label pull-left">状态:</div>
            <div class="search-item pull-left">
                <el-select v-model="searchState" clearable  size="small" style="width: 150px;height: 32px">
                    <el-option
                    v-for="item in stateArr"
                    :key="'orgLevel' + item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="button-item button-search pull-left" @click="searchHandle">查询</div>
        </div>
        <!-- 表格 -->
        <el-table border :data="listData" highlight-current-row header-row-class-name="table-header-class" :header-cell-style="{ 'background-color': '#F7F8FA' }"
        style="width: 100%; margin-top: 20px;" >
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column prop="loginName" label="账号（企业信用代码）" ></el-table-column>
          <el-table-column prop="nickName" label="单位全称" ></el-table-column>
          <el-table-column prop="createDate" label="申请时间" ></el-table-column>
          <el-table-column prop="" label="本机构等级" >
            <template slot-scope="scope" >
              {{ plenaryLevel2[scope.row.rawLevel] }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="变更等级" >
             <template slot-scope="scope" >
              {{ plenaryLevel2[scope.row.chgLevel] }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="状态" >
            <template slot-scope="scope" >
              {{ stateArr2[scope.row.state] }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="200">
            <template slot-scope="scope" >
              <div class="clecrfix">
                <div v-if="scope.row.state == 1" class="option-item pull-left option-normal" @click="agreeApply(scope.row.id, 2)">通过申请</div>
                <div v-if="scope.row.state == 1" class="option-item pull-left option-err" @click="agreeApply(scope.row.id, 3)">拒绝申请</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: right;padding-top: 20px;"
          @size-change="pageSizeHandle"
          @current-change="pageChangeHandle"
          :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
          layout="total, sizes, prev, pager, next, jumper"
          :page-size="pageSize"
          :current-page="pageNo"
          :page-count="totalPage"
          :total="totalCount">
        </el-pagination>
        <!-- <div class="table-box" v-if="totalCount">
            <table>
                <thead>
                    <tr>
                        <th>序号</th>
                        <th>账号（企业信用代码）</th>
                        <th>单位全称</th>
                        <th>申请时间</th>
                        <th>本机构等级</th>
                        <th>变更等级</th>
                        <th>状态</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in listData" :key="item.id">
                        <td>{{index+1}}</td>
                        <td>{{item.loginName || '-'}}</td>
                        <td>{{item.nickName || '-'}}</td>
                        <td>{{item.createDate || '-'}}</td>
                        <td>{{plenaryLevel2[item.rawLevel]  || '-'}}</td>
                        <td>{{plenaryLevel2[item.chgLevel]  || '-'}}</td>
                        <td>{{stateArr2[item.state]  || '-'}}</td>
                        <td class="clecrfix">
                            <div v-if="item.state == 1" class="option-item pull-left option-normal" @click="agreeApply(item.id, 2)">通过申请</div>
                            <div v-if="item.state == 1" class="option-item pull-left option-err" @click="agreeApply(item.id, 3)">拒绝申请</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <el-pagination
                style="text-align: right;padding-top: 20px;"
                @size-change="pageSizeHandle"
                @current-change="pageChangeHandle"
                :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
                layout="total, sizes, prev, pager, next, jumper"
                :page-size="pageSize"
                :current-page="pageNo"
                :page-count="totalPage"
            :total="totalCount">
            </el-pagination>
        </div>
        <div class="no-data-img"  v-if="noData"></div> -->
        <!-- 移出机构 -->
        <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="30%">
            <span>{{dialogMsg}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureHandler()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { MemberApplyList,MemberApplyState } from "@/http/api/InitiationApi";
export default {
  components: { },
  data () {
    return {
        listData: [],
        searchName: '',
        searchOrgLevel: '',
        searchState: '',
        noData: false,
        totalPage: 0,
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        // 大会等级
        plenaryLevel2: ['','会员','理事','常务理事','副会长','会员代表'],
        plenaryLevel: [{label:'会员',value: 1}, {label:'会员代表',value: 5}, {label:'理事',value: 2}, {label:'常务理事',value: 3}, {label:'副会长',value: 4}],
        // 本机构等级
        orgLevel: [{label:'会员',value: 1}, {label:'理事',value: 2}, {label:'常务理事',value: 3}, {label:'副会长',value: 4}],
        // 状态
        stateArr2:['','待处理','已通过','已拒绝'],
        stateArr: [{label:'待处理',value: 1},{label:'已通过',value: 2},{label:'已拒绝',value: 3}],
        loading: false, //页面加载
        dialogVisible: false,
        dialogTitle: '',
        dialogMsg: '',
        applyId: '',
        applyType: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
        this.loading = true;
        var item = {};
        item.type = 2;
        item.keyword = this.searchName || '';
        item.pageNo = this.pageNo || 1;
        item.pageSize = this.pageSize || 10;
        if (this.searchOrgLevel) {
            item.rawLevel = this.searchOrgLevel
        }
        if (this.searchState) {
            item.state = this.searchState
        }
        MemberApplyList(item).then(resp => {
            this.loading = false;
            if (resp.code === '000') {
                this.totalPage = resp.result.pages;
                this.totalCount = resp.result.total;
                this.pageNo = resp.result.current
                this.listData = resp.result.records;
                if (!resp.result.total) {
                    this.noData = true
                }
            } else {
                this.$Message.error("获取数据失败");
            }
        })
    },
    searchHandle () {
        this.pageNo = 1
        this.getList()
    },
    // 分页函数
    pageSizeHandle (val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.getList();
    },
    pageChangeHandle (val) {
        this.pageNo = val;
        this.getList();
    },
    agreeApply (id, type) {
        this.applyId = id;
        this.applyType = type;
        this.dialogVisible = true,
        this.dialogTitle = type == 2 ? '确认通过':'确认拒绝',
        this.dialogMsg= type == 2 ? '确定同意该单位会员变更分支机构等级？':'确定拒绝该单位会员变更分支机构等级？'
    },
    sureHandler () {
        this.loading = true;
        this.dialogVisible = false;
        MemberApplyState({id:this.applyId,state:this.applyType}).then(resp => {
            this.loading = false;
            if (resp.code == '000') {
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                setTimeout(()=>{
                    this.getList()
                },1000)
            }
        })

    }
  }
}
</script>
<style lang="less" scoped>
.no-data-img {
    width: 100%;
    height: 300px;
    background: url("../../assets/images/nodata.png") no-repeat center center;
}
</style>
